import { FaArrowLeft } from 'react-icons/fa'
import { BiScan } from 'react-icons/bi'
import { useHistory } from "react-router-dom"
import { isSafari } from 'react-device-detect'
import "./notification-navbar.css"
import NotificationMenu from '../notificationmenu/NotificationMenu'

const NotificationNavbar = ({ title, subTitle, isShownScanIcon, useBackButton, onChange }) => {

    const history = useHistory()

    const onBackClicked = () => {
        if (isSafari) {
            history.go(-2)
        } else {
            history.goBack()
        }
    }

    return (
        <div className="notification-navbar-container">
            <div className="notification-navbar-container-2">
                <div className="notification-navbar-title-container">
                    <p className="notification-navbar-title">{title}</p>
                    { subTitle && <p className="notification-navbar-sub-title">{subTitle}</p>}
                </div>
                { useBackButton && 
                    <a style={{background: 'none', zIndex: 10}} href='#'>
                        <FaArrowLeft className="notification-navbar-icon"
                            onClick={() => onBackClicked()}/>
                    </a>
                }
                {isShownScanIcon &&
                    <>
                        <div style={{width: '100%', background: 'none', height: 50}}/>
                        <a style={{background: 'none', zIndex: 10}} href='#'>
                            <BiScan className="notification-navbar-icon-scan"
                                onClick={() => onBackClicked()}/>
                        </a>
                    </>
                }
            </div>
            <div className="notification-navbar-menu-container">
                <NotificationMenu
                    onChange={onChange}/>
            </div>
        </div>
    )
}

NotificationNavbar.defaultProps = {
    title: '',
    isShownScanIcon: false,
    useBackButton: true
}

export default NotificationNavbar
