import { FaArrowLeft, FaSearch } from 'react-icons/fa'
import { BiScan } from 'react-icons/bi'
import { useHistory } from "react-router-dom"
import { isSafari } from 'react-device-detect'
import "./navbar-search.css"

const NavbarSearch = ({ placeholder, onSearch }) => {

    const history = useHistory()

    const onBackClicked = () => {
        if (isSafari) {
            history.go(-2)
        } else {
            history.goBack()
        }
    }

    return (
        <div className="navbarSearchContainer">
            <div className="navbarSearchTitleContainer">
                <div className="navbarSearchInputContainer">
                    <FaSearch className="navbarSearchIcon2"/>
                    <input className="navbarSearchInput"
                        placeholder={placeholder}
                        onKeyPress={(e) => e.key === 'Enter' && onSearch  && onSearch(e.target.value)}/>
                </div>
            </div> 
            <a style={{background: 'none', zIndex: 10}} href='#'>
                <FaArrowLeft className="navbarSearchIcon"
                    onClick={() => onBackClicked() }/>
            </a>
        </div>
    )
}

export default NavbarSearch
